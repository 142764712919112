// 'use strict';
// /*
//  * 配置编译环境和线上环境之间的切换
//  * BASE_URL: 域名地址
//  */
let BASE_URL = ''

if (process.env.NODE_ENV === 'development') {
    // 测试地址
    // BASE_URL = 'http://172.16.10.202:8087'
    // 张建力
    BASE_URL = 'http://172.16.10.231:8087'
    // 王文帅
    // BASE_URL = 'http://127.0.0.1:8087'
    // BASE_URL = 'https://hpm.yltcloud.com'
} else if (process.env.NODE_ENV === 'testing') {
    BASE_URL = 'http://172.16.10.202:8087'
} else if (process.env.NODE_ENV === 'production') {
    BASE_URL = 'https://hpm.yltcloud.com'
    // BASE_URL = 'http://10.0.0.119:8087'//西城妇幼的地址
}
// npm run dev 测试运行
// npm run build 线上打包
// npm run test 打包测试环境
// npm run prod 线上运行

// console.log(BASE_URL)

//导出
export default BASE_URL
