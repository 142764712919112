export default {
    login: "/openApi/login", //登录
    updatePwd: "/openApi/integrated/updatePwd",// 修改密码
    updateFirstPwd: "/openApi/integrated/updateFirstPwd",// 初次登录修改密码
    sms: "/openApi/sms",//发送验证码
    forceLogout: "/openApi/forceLogout", //退出登录
    getDepartmentList: "/openApi/integrated/getDepartmentList", //查询科室列表 分页
    departmentList: "/openApi/integrated/hospitalBase/departmentList",// 科室数据更新时间列表
    getDepartmentUserList: "/openApi/integrated/getDepartmentUserList", //获取科室用户列表 分页
    addAndEdit: "/openApi/integrated/addAndEdit",// 添加或修改科室用户
    addDepartment: "/openApi/integrated/addDepartment",// 添加或修改科室信息
    removeDepartmentUser: "/openApi/integrated/removeDepartmentUser",// 删除科室用户
    removeDepartment: "/openApi/integrated/removeDepartment",// 删除科室
    getBusinessList: "/openApi/integrated/getBusinessList",// 科室服务项目列表 分页
    editDepartmentBusiness: "/openApi/integrated/editDepartmentBusiness",// 添加或修改项目
    getHospitalBusinessList: "/openApi/integrated/getHospitalBusinessList",// 医院年份列表 分页
    addHospitalBusiness: "/openApi/integrated/addHospitalBusiness", //添加医院年份信息
    updateHospitalSalary: "/openApi/integrated/updateHospitalSalary", //修改医院 薪资
    getHospitalBusiness: "/openApi/integrated/getHospitalBusiness",// 获取薪资信息
    getDepartmentNotPage: "/openApi/integrated/getDepartmentNotPage",// 获取医院所属科室列表
    getDepartmentYearList: "/openApi/departmentYear/getDepartmentYearList",// 查询科室年份列表 分页
    getDepartmentYearDetailsList: "/openApi/departmentYear/getDepartmentYearDetailsList",//查询科室年份详情列表 分页
    getDepartmentPriceRatioList: "/openApi/departmentYear/getDepartmentPriceRatioList", //科室月份工作量数据 分页
    updateDepartmentPriceRatio: "/openApi/departmentYear/updateDepartmentPriceRatio",// 修改单条填报数据
    coefficientOfDifficulty: "/openApi/departmentYear/coefficientOfDifficulty",// 计算项目难度值
    getDepartmentYearDataList: "/openApi/departmentYear/getDepartmentYearDataList",// 获取科室年度数据 分页
    getDiffValue: "/openApi/departmentYear/getDiffValue", //年度数据获取 基期难度系数测算
    departmentMonitorChart: "/openApi/departmentYear/departmentMonitorChart", //科室监控图表
    removeMonitorChart: "/openApi/departmentYear/removeMonitorChart",//删除科室填报监测数据
    editDepartmentMonitor: "/openApi/departmentYear/editDepartmentMonitor",// 添加科室填报监测数据
    updateBusinessBase: "/openApi/integrated/updateBusinessBase",// 修改项目是否是 基准项目
    getDepartmentMonitorList: "/openApi/departmentYear/getDepartmentMonitorList",// 科室监控列表
    getDepartmentSalaryList: "/openApi/departmentYear/getDepartmentSalaryList",// 科室薪酬数据列表 分页
    editDepartmentSalary: "/openApi/departmentYear/editDepartmentSalary",// 科室薪酬添加数据
    getDepartmentSalaryDetails: "/openApi/departmentYear/getDepartmentSalaryDetails",// 科室薪资月份列表
    updateDepartmentSalaryDetails: "/openApi/departmentYear/updateDepartmentSalaryDetails",// 修改科室薪资月份表
    getDepartmentPriceRatioStandList: "/openApi/departmentYear/getDepartmentPriceRatioStandList", //科室标化工作总量月份数据 分页
    createSimulation: "/openApi/departmentYear/createSimulation",// 2022-4-19 新接口 创建科室模拟测算
    getSimulationList: "/openApi/departmentYear/getSimulationList",// 科室模拟测算列表
    calculateData: "/openApi/departmentYear/calculateData",// 2022-4-19 新接口 模拟测算（进行计算数据）
    removeSimulation: "/openApi/departmentYear/removeSimulation",// 删除科室模拟测算
    importSimulationExcel: "/openApi/departmentYear/importSimulationExcel",// 导出模拟测算
    getSimulationCalculate: "/openApi/departmentYear/getSimulationCalculate", //获取单个模拟测算数据
    getHospitalYear: "/openApi/integrated/getHospitalYear",// 获取医院所有年份 不分页
    getYearAndMonth: "/openApi/departmentYear/getYearAndMonth",// 模拟测算中获取科室下各个年度月份工作量填报信息
    getHospitalYearCalculation: "/openApi/integrated/getHospitalYearCalculation",// 进入医疗服务项目页面先查询 所用计算的年份
    calculationPriceRatio: "/openApi/integrated/calculationPriceRatio",// 根据年份计算标化价值
    hospitalList: "/openApi/integrated/hospitalBase/hospitalList",// 医院数据更新时间列表
    saveHospitalBaseOne: "/openApi/integrated/hospitalBase/saveHospitalBaseOne",// 医院 机构、人员、床位及工作量 添加
    saveHospitalBaseTwo: "/openApi/integrated/hospitalBase/saveHospitalBaseTwo",// 医院 固定资产 添加
    saveHospitalBaseThree: "/openApi/integrated/hospitalBase/saveHospitalBaseThree",// 医院 收入 添加
    saveHospitalBaseFour: "/openApi/integrated/hospitalBase/saveHospitalBaseFour",// 医院 支出 添加
    saveHospitalBaseFive: "/openApi/integrated/hospitalBase/saveHospitalBaseFive",// 医院 负债 添加
    saveHospitalBaseSix: "/openApi/integrated/hospitalBase/saveHospitalBaseSix",// 医院 医疗应收款 添加
    saveIndexOne: "/openApi/integrated/hospitalBase/saveIndexOne",// 医院 效率、效益及装备 添加
    saveIndexTwo: "/openApi/integrated/hospitalBase/saveIndexTwo",// 医院 负债及偿债能力 添加
    saveIndexThree: "/openApi/integrated/hospitalBase/saveIndexThree",// 医院 药品 添加
    saveIndexFour: "/openApi/integrated/hospitalBase/saveIndexFour",// 医院 次均费用及财政补偿 添加
    saveIndexFive: "/openApi/integrated/hospitalBase/saveIndexFive",// 医院 支出分析 添加
    saveDepartmentBaseOne: "/openApi/integrated/hospitalBase/saveDepartmentBaseOne",// 科室 人员架构 添加
    saveDepartmentBaseTwo: "/openApi/integrated/hospitalBase/saveDepartmentBaseTwo",// 科室 科室结构 添加
    saveDepartmentBaseThree: "/openApi/integrated/hospitalBase/saveDepartmentBaseThree",// 科室 运营效率 添加
    saveDepartmentBaseFour: "/openApi/integrated/hospitalBase/saveDepartmentBaseFour",// 科室 薪酬水平 添加
    getBaseDetails: "/openApi/integrated/hospitalBase/getBaseDetails",// 数据回显
    downBusinessExcel: "/openApi/integrated/downBusinessExcel",// 导出医疗服务项目excel模板
    synProject: "/openApi/job/project",// 导出医疗服务项目excel模板
    importBusinessExcel: "/openApi/integrated/importBusinessExcel",// 导入医疗服务项目excel
    exportYearExcel: "/openApi/integrated/exportYearExcel",// 导出科室年份下所有月数据
    exportMonthExcel: "/openApi/integrated/exportMonthExcel",// 导出科室工作量单月数据
    exportPriceRatioMonthDetails: "/openApi/integrated/exportPriceRatioMonthDetails",// 导出工作量单月数据填报
    importPriceRatioExcel: "/openApi/integrated/importPriceRatioExcel",// 导入科室工作量填报数据
    updateBaseClassByStandWork: "/openApi/integrated/updateBaseClassByStandWork",// 重新计算标化工作量
    standardProjectPriceRatio: "/openApi/integrated/standardProjectPriceRatio",//基准项目
    findEquivalent: "/openApi/departmentYear/findEquivalent", // 通过获取参考当量的数据计算出对应的参考当量系数
    strList: "/openApi/structuralAdjustmen/list",//结构调整策略列表
    strAdd: "/openApi/structuralAdjustmen/add",// 新增结构调整策略
    strAdjList: "/openApi/structuralAdjustmentDetail/list",//结构调整策略详情列表
    updateItemProperties: "/openApi/structuralAdjustmentDetail/updateItemProperties",//更改项目属性计算调节系数
    configureStrategy: "/openApi/departmentYear/configureStrategy",//配置结构调整策略
    deleteList: "/openApi/structuralAdjustmen/delete",//删除结构调整策略
    updateAdjustmentCoefficient: "/openApi/structuralAdjustmentDetail/updateAdjustmentCoefficient",//更改调节系数
    listhosInward: "/openApi/hosInpatientWard/list",//病区列表（带分页）
    addInward: "/openApi/hosInpatientWard/add",//新增病区信息
    editTnward: "/openApi/hosInpatientWard/update",//编辑修改病区信息
    deleteTnward: "/openApi/hosInpatientWard/delete",//删除病区信息
    findByhosInward: "/openApi/hosInpatientWard/findBy",//病区列表（无分页）
    findBySys: "/system/dict/data/findBy",//根据字典类型查询字典数据中的信息
    addhosDepartmentUser: "/openApi/hosDepartmentUser/add",//新增科室人员信息
    listhosDepartmentUser: "/openApi/hosDepartmentUser/list",//科室人员信息列表(带分页)
    hosUpload: "/openApi/sysUploadFiles/upload",//文件上传
    By: "/openApi/hosDepartmentUser/findBy",//科室人员信息列表（无分页）
    sysgetFile: "/openApi/sysUploadFiles/getFile",//获取文件信息
    resUpdate: "/openApi/hosScientificResearchFillings/update",//编辑/修改科研绩效指标填报
    addhosdpl: "/openApi/hosDisciplines/add",//新增学科
    listhosdpl: "/openApi/hosDisciplines/list",//学科管理列表
    updatehosdpl: "/openApi/hosDisciplines/update",//编辑修改学科
    delehosdpl: "/openApi/hosDisciplines/delete",//删除学科
    hospostlistAdd: "/openApi/hosPostResponsibilitys/add",//新增岗位职责
    hospostList: "/openApi/hosPostResponsibilitys/list",//岗位职责列表 (带分页)
    hospostDelete: "/openApi/hosPostResponsibilitys/delete",//删除岗位职责
    hospostUpdate: "/openApi/hosPostResponsibilitys/update",//修改岗位职责
    hosdepAdd: "/openApi/hosDepartmentBasicInformations/add",//新增科室基础信息
    hosdepUpdate: "/openApi/hosDepartmentBasicInformations/update",//编辑修改科室基础信息
    hoddepFind: "/openApi/hosDepartmentBasicInformations/findByKey",//获取科室基础信息
    hoddepList: "/openApi/hosDepartmentBasicInformations/list",//科室基础信息分页列表
    findDetail: "/openApi/hosDepartmentBasicInformations/findDetail",//通过科室id获取科室基础信息
    hosdepUser: "/openApi/hosDepartmentUser/list",//科室人员信息列表(带分页)
    hosPostRe: "/openApi/hosPostResponsibilitys/findBy",//岗位职责列表 (无分页)
    hosDisciplines: "/openApi/hosDisciplines/findBy",//学科管理列表 (无分页)
    findDepartment: "/openApi/integrated/findDepartment",//根据医院id查询科室列表 (无分页)
    deleteDepartment: "/openApi/hosDepartmentUser/delete",//删除科室人员信息
    updaDepartment: "/openApi/hosDepartmentUser/update",//编辑修改科室人员信息
    hosfindByKey: "/openApi/hosDepartmentUser/findByKey",//获取科室人员详细信息
    judgeHosDiscipline: "/openApi/hosDisciplines/judgeHosDiscipline",//判断学科是否已存在
    judgeInpatientWard: "/openApi/hosInpatientWard/judgeInpatientWard",//判断科室下的病区是否已存在
    judgeHosDepartmentUser: "/openApi/hosDepartmentUser/judgeHosDepartmentUser",//判断科室人员的工号是否已存在
    judgeDepartment: "/openApi/integrated/judgeDepartment",//判断科室是否已存在
    optionselect: "/system/dict/type/optionselect",//获取字典选择框列表
    getRouters: "/openApi/getRouters",//菜单权限
    exportExcel: "/openApi/hosDepartmentUser/exportExcel",//导出人员信息
    departmentMedicalSort:"/openApi/departmentMedicalSort/list",//医嘱小类分页列表
    depIsExist:"/openApi/departmentMedicalSort/isExist",//校验医嘱小类是否重复
    deleMedical:"/openApi/departmentMedicalSort/delete",//删除医嘱小类
    addMedical:"/openApi/departmentMedicalSort/addDepartmentMedicalSort",//新增医嘱小类
    getMedical:"/openApi/departmentMedicalSort/get",//回显医嘱小类
    updateMedical:"/openApi/departmentMedicalSort/update",//修改医嘱小类
    getDepartmentWorkLoadList:"/openApi/departmentYear/getDepartmentWorkLoadList",//科室标化工作量
    pageList:"/openApi/hosBasicsDepartment/pageList",//基础科室列表
    insertBasicDepartment:"/openApi/hosBasicsDepartment/insertBasicDepartment",//添加基础科室
    deletBasicDepartment:"/openApi/hosBasicsDepartment/delete",//删除基础科室
    updateBasicDepartment:"/openApi/hosBasicsDepartment/update",//修改基础科室
    findCurrentYearAndMonthInformation:"/openApi/performance/findCurrentYearAndMonthInformation",//科室绩效数据填报列表
    perdatRep:"/openApi/performance/update",//填报
    approved:"/openApi/performance/approved",//审核通过
    reject:"/openApi/performance/reject",//驳回
    findById:"/openApi/performance/findById",//查看详情
    calculate:"/openApi/performance/calculate",//核算
    perexport:"/openApi/performance/export",//
    fillInStatus:"/openApi/performance/fillInStatus",//填报状态
    performanceSumAnalyse:"/openApi/analyse/performanceSumAnalyse",//绩效总额与占比表
    performanceMAvgAnalyse:"/openApi/analyse/performanceMAvgAnalyse",//月均绩效与绩效排名分析
    performancePAvgAnalyse:"/openApi/analyse/performancePAvgAnalyse",//人均绩效与绩效排名分析
    getDateTime:"/openApi/performance/getDateTime",//获取时间（大于25为本月）
    recountProjectAmount:"/openApi/departmentYear/recountProjectAmount",//重新计算发生量
    incomePerformanceAnalyse:"/openApi/analyse/incomePerformanceAnalyse",//收入绩效关系分析
    effectiveIncomePerformanceAnalyse:"/openApi/analyse/effectiveIncomePerformanceAnalyse",//有效收入绩效关系分析
    incomeEffectiveIncomePerformanceRank:"/openApi/analyse/incomeEffectiveIncomePerformanceRank", // 收入排名，有效收入排名，绩效排名
    materialDrugProportion:"/openApi/analyse/materialDrugProportion",//药占比 材料占比分析
    incomeAvgRankAnalyse:"/openApi/analyse/incomeAvgRankAnalyse",//人均收入与排名分析
    effectiveIncomeAvgRankAnalyse:"/openApi/analyse/effectiveIncomeAvgRankAnalyse",//人均有效收入与排名分析
    effectiveAvgPerformanceAvgAnalyse:"/openApi/analyse/effectiveAvgPerformanceAvgAnalyse",//人均有效收入与绩效关系分析
    analyseList:"/openApi/analyse/analyseList",//驱动型绩效数据的总表
    exportAnalyse:"/openApi/analyse/exportAnalyse",//导出总表
    exportWorkLoad:"/openApi/departmentYear/exportWorkLoad",//导出标化工作量
    findBasicsDepartmentByDepartmentId:"/openApi/hosBasicsDepartment/findBasicsDepartmentByDepartmentId",//查询基础科室列表
    getDepartmentWorkLoad:"/openApi/departmentYear/getDepartmentWorkLoad",//查询工作量
    attpageList:"/openApi/attendance/pageList",//科室人员信息列表
    attinsert:"/openApi/attendance/insert",//新增科室人员信息
    attupdate:"/openApi/attendance/update",//修改科室人员信息
    attdelete:"/openApi/attendance/delete",//删除科室人员信息
    attexport:"/openApi/attendance/export",//考勤管理导出
    // 科室考核指标管理
    indexList:"/openApi/assessTarget/assessTargetPageList", // 列表
    updateIndexDetail:"/openApi/assessTargetDetails/updateAssessTargetDetails", // 修改权重分值 和 基数
    indexMonthList:"/openApi/assessTargetMonth/targetMonthPageList", // 科室考核得分列表
    updateIndexMonth:"/openApi/assessTargetMonth/updateAssessTargetMonth", // 修改当月得分
    calculateBase:"/openApi/assessTargetDetails/calculate", // 重新计算基数
    calculateMonth:"/openApi/assessTargetDetails/calculateMonth", // 重新计算指标得分
    deleteIndex:"/openApi/assessTargetDetails/deleteTarget", // 删除指标

























































};

