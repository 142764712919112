import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//将原有的push方法地址，保存起来
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolved, onRejected) {
    if (onResolved === undefined && onRejected === undefined) {
        return originalPush.call(this, location).catch(() => { })
    } else {
        return originalPush.call(this, location, onResolved, onRejected)
    }
}

// 创建一个路由器，管理所有的路由
const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: resolve => require(['@/components/Login/Login'], resolve),
        },
        // 登录
        {
            path: '/login',
            name: 'login',
            component: resolve => require(['@/components/Login/Login'], resolve),
        },
        // 首页
        {
            path: '/index',
            name: 'index',
            component: resolve => require(['@/components/Index/Index.vue'], resolve),
        },
        // 科室用户管理
        {
            path: '/department',
            name: 'department',
            component: resolve => require(['@/components/Department/department.vue'], resolve),
            children: [
                {
                    path: '/depIndex',
                    component: resolve => require(['@/components/Department/DepIndex/DepIndex'], resolve),
                    meta: {
                        activeMenu: '/depIndex', // 高亮
                    }
                },
                {
                    path: '/depTwo',
                    component: resolve => require(['@/components/Department/Two/Two'], resolve),
                    meta: {
                        activeMenu: '/depTwo', // 高亮
                    }
                },
                {
                    path: '/hospital',
                    component: resolve => require(['@/components/Department/Hospital/Hospital'], resolve),
                    meta: {
                        activeMenu: '/hospital', // 高亮
                    }
                },
                {
                    path: '/meadSubca',
                    component: resolve => require(['@/components/Department/Hospital/meadSubca'], resolve),
                    meta: {
                        activeMenu: '/meadSubca', // 高亮
                    }
                },
                {
                    path: '/measurement',
                    component: resolve => require(['@/components/Department/Measurement/Measurement'], resolve),
                    meta: {
                        activeMenu: '/measurement', // 高亮
                    }
                },
                {
                    path: '/MedireInfo',
                    component: resolve => require(['@/components/Department/MedireInfo/MedireInfo'], resolve),
                    meta: {
                        activeMenu: '/MedireInfo', // 高亮
                    }
                },
                {
                    path: '/PersonInfo',
                    component: resolve => require(['@/components/Department/PersonInfo/PersonInfo'], resolve),
                    meta: {
                        activeMenu: '/PersonInfo', // 高亮
                    }
                },
                {
                    path: '/measurementInside',
                    component: resolve => require(['@/components/Department/Measurement/MeasurementInside'], resolve),
                    meta: {
                        activeMenu: '/measurementInside', // 高亮
                    }
                },
                {
                    path: '/oridisman',
                    component: resolve => require(['@/components/Department/Income/oridisman'], resolve),
                    meta: {
                        activeMenu: '/oridisman', // 高亮
                    }
                },
                {
                    path: '/oridepman',
                    component: resolve => require(['@/components/Department/Income/oridepman'], resolve),
                    meta: {
                        activeMenu: '/oridepman', // 高亮
                    }
                },
                {
                    path: '/oridebaman',
                    component: resolve => require(['@/components/Department/Income/oridebaman'], resolve),
                    meta: {
                        activeMenu: '/oridebaman', // 高亮
                    }
                },
                {
                    path: '/oriporeman',
                    component: resolve => require(['@/components/Department/Income/oriporeman'], resolve),
                    meta: {
                        activeMenu: '/oriporeman', // 高亮
                    }
                },
                {
                    path: '/oripeinman',
                    component: resolve => require(['@/components/Department/Income/oripeinman'], resolve),
                    meta: {
                        activeMenu: '/oripeinman', // 高亮
                    }
                },
                {
                    path: '/hospitalTwo',
                    component: resolve => require(['@/components/Department/Hospital/HospitalTwo'], resolve),
                    meta: {
                        activeMenu: '/hospitalTwo', // 高亮
                    }
                },
                {
                    path: '/deplist',
                    component: resolve => require(['@/components/Department/Deplist/Deplist'], resolve),
                    meta: {
                        activeMenu: '/deplist', // 高亮
                    }
                }, {
                    path: '/basicdata',
                    component: resolve => require(['@/components/Department/Basicdata/Basicdata'], resolve),
                    meta: {
                        activeMenu: '/basicdata', // 高亮
                    }
                },  {
                    path: '/hosCdata',
                    component: resolve => require(['@/components/Department/Basicdata/hosCdata'], resolve),
                    meta: {
                        activeMenu: '/hosCdata', // 高亮
                    }
                }, {
                    path: '/workMonit',
                    component: resolve => require(['@/components/Department/WorkMonit/WorkMonit'], resolve),
                    meta: {
                        activeMenu: '/workMonit', // 高亮
                    }
                },
                {
                    path: '/depsimulation',
                    component: resolve => require(['@/components/Department/Depsimulation/Depsimulation'], resolve),
                    meta: {
                        activeMenu: '/depsimulation', // 高亮
                    }
                },
                {
                    path: '/coperman',
                    component: resolve => require(['@/components/Department/Coperman/Coperman'], resolve),
                    meta: {
                        activeMenu: '/coperman', // 高亮
                    }
                },
                {
                    path: '/totalstand',
                    component: resolve => require(['@/components/Department/Totalstand/Totalstand'], resolve),
                    meta: {
                        activeMenu: '/totalstand', // 高亮
                    }
                },
                {
                    path: '/performance',
                    component: resolve => require(['@/components/Department/Performance/performance'], resolve),
                    meta: {
                        activeMenu: '/performance', // 高亮
                    }
                },
                {
                    path: '/performanceInside',
                    component: resolve => require(['@/components/Department/Performance/performanceInside'], resolve),
                    meta: {
                        activeMenu: '/performanceInside', // 高亮
                    }
                },
                {
                    path: '/totalstandNoHo',
                    component: resolve => require(['@/components/Department/Totalstand/TotalstandNoHo'], resolve),
                    meta: {
                        activeMenu: '/totalstandNoHo', // 高亮
                    }
                },
                {
                    path: '/personInforManage',
                    component: resolve => require(['@/components/Department/personInfor/personInforManage'], resolve),
                    meta: {
                        activeMenu: '/personInforManage', // 高亮
                    }
                },
                {
                    path: '/personInforAttend',
                    component: resolve => require(['@/components/Department/personInfor/personInforAttend'], resolve),
                    meta: {
                        activeMenu: '/personInforAttend', // 高亮
                    }
                },
                {
                    path: '/personAttMan',
                    component: resolve => require(['@/components/Department/personInfor/personAttMan'], resolve),
                    meta: {
                        activeMenu: '/personAttMan', // 高亮
                    }
                },
                {
                    path: '/salaryList',
                    component: resolve => require(['@/components/Department/SalaryList/SalaryList'], resolve),
                    meta: {
                        activeMenu: '/salaryList', // 高亮
                    }
                },
                {
                    path: '/salaryListInside',
                    component: resolve => require(['@/components/Department/SalaryList/SalaryListInside'], resolve),
                    meta: {
                        activeMenu: '/salaryListInside', // 高亮
                    }
                },
                // 结构调整
                {
                    path: '/structadju',
                    component: resolve => require(['@/components/Department/Structadju/structadju'], resolve),
                    meta: {
                        activeMenu: '/structadju', // 高亮
                    }
                },
                {
                    path: '/twoNoHo',
                    component: resolve => require(['@/components/Department/Two/TwoNoHo'], resolve),
                    meta: {
                        activeMenu: '/twoNoHo', // 高亮
                    }
                },
                // 科室绩效数据填报
                {
                    path: '/deperdatFil',
                    component: resolve => require(['@/components/Department/Deperform/deperdatFil'], resolve),
                    meta: {
                        activeMenu: '/deperdatFil', // 高亮
                    }
                },
                // 当月绩效填报审核
                {
                    path: '/revmopeRepo',
                    component: resolve => require(['@/components/Department/Deperform/revmopeRepo'], resolve),
                    meta: {
                        activeMenu: '/revmopeRepo', // 高亮
                    }
                },
            //     绩效核算
                {
                    path: '/perAcco',
                    component: resolve => require(['@/components/Department/Deperform/perAcco'], resolve),
                    meta: {
                        activeMenu: '/perAcco', // 高亮
                    }
                },
            //     妇产科-绩效数据管理
                {
                    path: '/perdaManag',
                    component: resolve => require(['@/components/Department/Deperform/perdaManag'], resolve),
                    meta: {
                        activeMenu: '/perdaManag', // 高亮
                    }
                },
                // 质控科-绩效数据管理
                {
                    path: '/quaCondeperdatFil',
                    component: resolve => require(['@/components/Department/Deperform/quaCondeperdatFil'], resolve),
                    meta: {
                        activeMenu: '/quaCondeperdatFil', // 高亮
                    }
                },
                // 绩效办-绩效数据管理
                {
                    path: '/perfordeperdatFil',
                    component: resolve => require(['@/components/Department/Deperform/perfordeperdatFil'], resolve),
                    meta: {
                        activeMenu: '/perfordeperdatFil', // 高亮
                    }
                },
// 体检科-绩效数据管理
                {
                    path: '/phyexdeperdatFil',
                    component: resolve => require(['@/components/Department/Deperform/phyexdeperdatFil'], resolve),
                    meta: {
                        activeMenu: '/phyexdeperdatFil', // 高亮
                    }
                },
                // echarts图
                {
                    path: '/echartPlot',
                    component: resolve => require(['@/components/Department/EchartPlot/echartPlot'], resolve),
                    meta: {
                        activeMenu: '/echartPlot', // 高亮
                    }
                },
                // 指标管理--普通管理员
                {
                    path: '/indexManage',
                    component: resolve => require(['@/components/Department/index/indexManage.vue'], resolve),
                    meta: {
                        activeMenu: '/indexManage', // 高亮
                    }
                },
                // 指标管理--绩效办 医院管理员
                {
                    path: '/indexH',
                    component: resolve => require(['@/components/Department/index/indexManage-h.vue'], resolve),
                    meta: {
                        activeMenu: '/indexH', // 高亮
                    }
                },
                // 考核指标得分管理--普通管理员
                {
                    path: '/indexScoreManage',
                    component: resolve => require(['@/components/Department/index/indexScoreManage.vue'], resolve),
                    meta: {
                        activeMenu: '/indexScoreManage', // 高亮
                    }
                },
                // 考核指标得分管理--绩效办 医院管理员
                {
                    path: '/indexScoreManageH',
                    component: resolve => require(['@/components/Department/index/indexScoreManage-h.vue'], resolve),
                    meta: {
                        activeMenu: '/indexScoreManageH', // 高亮
                    }
                },

                // 新页面
                {
                    path: '/newpage',
                        component: resolve => require(['@/components/Department/Deperform/newpage'], resolve),
                        meta: {
                        activeMenu: '/newpage', // 高亮
                    }
                }
            ]
        },
    ]
})


// 暴露路由器
export default router
